import React from "react"
import News from "../../../blocks/en/news_page"
import { Link, graphql } from "gatsby"

export const pageQuery = graphql`
query {
  news: newsYaml(slug: { eq: "mashal_new_client_for_legal" }) {
    ...NewsInfoEn
  }
}`

export default class NewsTextEn extends News {
  renderNews() {
    return (
      <div className="row">
        <div className="col-xl-12">
          <p>Catenaccio Sports Agency announces the signing of an agreement with FC Mashal to provide legal consultations for an Uzbekistan football club.</p>
        </div>
      </div>
    )
  }
}